import angularJS from '@shared/angularJS/global.ng';
import {IModule, ITemplateCacheService} from 'angular';
import surfacePondereeHelpTemplate from '@legacy/templates/estimations/components/item/evaluation/surface-ponderee-help';
import statutTemplate from '@legacy/templates/estimations/components/item/label/statut';
import valeurTemplate from '@legacy/templates/estimations/components/item/label/valeur';
import actionsTemplate from '@legacy/templates/estimations/components/item/references/crud/actions';
import crudTemplate from '@legacy/templates/estimations/components/item/references/crud';
import referencesListTemplate from '@legacy/templates/estimations/components/item/references/list';
import evaluationTemplate from '@legacy/templates/estimations/components/item/evaluation';
import boxRemisTemplate from '@legacy/templates/estimations/components/box.remis';
import listTemplate from '@legacy/templates/estimations/components/list';
import referencesTemplate from '@legacy/templates/estimations/components/references';
import onsaleReferencesCriteriaHelpTemplate from '@legacy/templates/estimations/controllers/item/evaluation/onsale-references/criteria/help';
import onsaleReferencesCriteriaTemplate from '@legacy/templates/estimations/controllers/item/evaluation/onsale-references/criteria';
import referencesCriteriaHelpTemplate from '@legacy/templates/estimations/controllers/item/evaluation/references/criteria/help';
import evaluationReferencesCriteriaTemplate from '@legacy/templates/estimations/controllers/item/evaluation/references/criteria';
import lifetimeTemplate from '@legacy/templates/estimations/controllers/item/lifetime';
import modalContactTemplate from '@legacy/templates/estimations/controllers/modals/item/references.import/contact';
import modalArchiveTemplate from '@legacy/templates/estimations/controllers/modals/item/archive';
import modalReferencesImportTemplate from '@legacy/templates/estimations/controllers/modals/item/references.import';
import modalBoxRemisTemplate from '@legacy/templates/estimations/controllers/modals/box.remis';
import referencesCriteriaTemplate from '@legacy/templates/estimations/controllers/references/criteria';
import advertisementTemplate from '@legacy/templates/estimations/advertisement';
import deprecatedValeurTemplate from '@legacy/templates/estimations/deprecated_valeur';
import keywordsHelpTemplate from '@legacy/templates/estimations/keywords.help';

export default function getEstimationsTemplates(module: IModule): void {
    (function (angular) {
        'use strict';

        module.run(templates);

        /**
         * Templates initialization
         *
         * @param $templateCache
         */
        templates.$inject = ['$templateCache'];
        function templates($templateCache: ITemplateCacheService) {
            $templateCache.put('src/app/legacy/templates/estimations/components/item/evaluation/surface-ponderee-help.html', surfacePondereeHelpTemplate);
            $templateCache.put('src/app/legacy/templates/estimations/components/item/label/statut.html', statutTemplate);
            $templateCache.put('src/app/legacy/templates/estimations/components/item/label/valeur.html', valeurTemplate);
            $templateCache.put('src/app/legacy/templates/estimations/components/item/references/crud/actions.html', actionsTemplate);
            $templateCache.put('src/app/legacy/templates/estimations/components/item/references/crud.html', crudTemplate);
            $templateCache.put('src/app/legacy/templates/estimations/components/item/references/list.html', referencesListTemplate);
            $templateCache.put('src/app/legacy/templates/estimations/components/item/evaluation.html', evaluationTemplate);
            $templateCache.put('src/app/legacy/templates/estimations/components/box.remis.html', boxRemisTemplate);
            $templateCache.put('src/app/legacy/templates/estimations/components/list.html', listTemplate);
            $templateCache.put('src/app/legacy/templates/estimations/components/references.html', referencesTemplate);
            $templateCache.put('src/app/legacy/templates/estimations/controllers/item/evaluation/onsale-references/criteria/help.html', onsaleReferencesCriteriaHelpTemplate);
            $templateCache.put('src/app/legacy/templates/estimations/controllers/item/evaluation/onsale-references/criteria.html', onsaleReferencesCriteriaTemplate);
            $templateCache.put('src/app/legacy/templates/estimations/controllers/item/evaluation/references/criteria/help.html', referencesCriteriaHelpTemplate);
            $templateCache.put('src/app/legacy/templates/estimations/controllers/item/evaluation/references/criteria.html', evaluationReferencesCriteriaTemplate);
            $templateCache.put('src/app/legacy/templates/estimations/controllers/item/lifetime.html', lifetimeTemplate);
            $templateCache.put('src/app/legacy/templates/estimations/controllers/modals/item/references.import/contact.html', modalContactTemplate);
            $templateCache.put('src/app/legacy/templates/estimations/controllers/modals/item/archive.html', modalArchiveTemplate);
            $templateCache.put('src/app/legacy/templates/estimations/controllers/modals/item/references.import.html', modalReferencesImportTemplate);
            $templateCache.put('src/app/legacy/templates/estimations/controllers/modals/box.remis.html', modalBoxRemisTemplate);
            $templateCache.put('src/app/legacy/templates/estimations/controllers/references/criteria.html', referencesCriteriaTemplate);
            $templateCache.put('src/app/legacy/templates/estimations/advertisement.html', advertisementTemplate);
            $templateCache.put('src/app/legacy/templates/estimations/deprecated_valeur.html', deprecatedValeurTemplate);
            $templateCache.put('src/app/legacy/templates/estimations/keywords.help.html', keywordsHelpTemplate);
        }
    })(angularJS);
}
