import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SearchOpenComponent} from '@shared/search/open/search.open.component';
import {SearchHighlightDirective} from '@shared/search/highlight/search.highlight.directive';
import {FaModule} from '@shared/fontawesome/fa.module';

@NgModule({
    declarations: [SearchOpenComponent, SearchHighlightDirective],
    exports: [SearchOpenComponent, SearchHighlightDirective],
    imports: [CommonModule, FaModule],
})
export class SearchModule {
}
