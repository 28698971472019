import Environment from '@env/environment.model';

export default new Environment(
    {
        baseUrl: 'https://api.esk.immo',
        client: {
            id: '2_26uj6p0r0zk0kck4w488w4okwkg84ck88swk44kggg800gscow',
            secret: '4nh8myq4iz6sowkkkcg408ooocs80g80sk80gcsck0os4gcgg8',
        },
        mediaUrl: 'https://media.esk.immo',
        pathUrl: '',
    },
    {app: {id: '296510091190188'}},
    {appId: 'bbb0ae78bded37fd7092ba48c664fa52', url: 'https://api.openweathermap.org'},
    {baseUrl: 'http://my.noty.fr'},
    {
        partnerToken: '0BD4AA44-C432-0C5F-AC1A-187A6D860F93',
        prefix: '',
        url: 'https://software.previsite.com/partner.php',
    },
    {dsn: 'https://847d650596f548a4b401989837c21d07@sentry.io/62938', enabled: true, environment: 'prod'}
);
