import {AfterViewInit, Directive, ElementRef, inject, Input, SecurityContext} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Directive({selector: '[highlight]'})
export class SearchHighlightDirective implements AfterViewInit {
    @Input('highlight') searchTerm!: string | undefined;
    private _domSanitizer = inject(DomSanitizer);
    private _elementRef = inject(ElementRef<HTMLElement>);

    ngAfterViewInit(): void {
        if (!this._elementRef.nativeElement) {
            return;
        }

        let content = this._elementRef.nativeElement.textContent;

        if (this.searchTerm && content) {
            const search = this.searchTerm
                .replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
                .replace('a', '[aàâä]')
                .replace('e', '[eéèëê]')
                .replace('i', '[iïî]')
                .replace('o', '[oôö]')
                .replace('u', '[uùûü]')
                .replace('c', '[cç]');
            const regex = new RegExp(search, 'gui');
            const newText = content.replace(regex, match => `<mark class="highlight">${match}</mark>`);

            content = this._domSanitizer.sanitize(SecurityContext.HTML, newText);
        }

        this._elementRef.nativeElement.innerHTML = content!;
    }
}
