import {Component, Directive, ElementRef, Injector} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Component({selector: 'esk-estimations-advertisement', template: '<div appNgEstimationsAdvertisement></div>'})
export class NgEstimationsAdvertisementComponent {
}

@Directive({selector: '[appNgEstimationsAdvertisement]'})
export class NgEstimationsAdvertisementDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskEstimationsAdvertisementCtrl', elementRef, injector);
    }
}
