import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';

export default function getEstimationsAdvertisementCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.component('eskEstimationsAdvertisementCtrl', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/estimations/advertisement.html',
        });

        /**
         * Controller
         */
        Controller.$inject = [];
        function Controller() {
        }
    })(angularJS);
}
