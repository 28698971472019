<div class="tw-mb-5"><h3><fa-icon [icon]="['fal', 'file']"/> {{ options.titre }}</h3></div>

<form #templatesForm="ngForm" (submit)="createDocument()" class="sm:tw-w-[30rem] tw-space-y-4">
  @if (courrierCategories.length > 0) {
    <div>
      <label>Courrier introductif</label>

      <app-template-select [options]="{name: 'modelCourrier', placeholder: 'Sélectionnez un courrier introductif au rapport'}"
                           [categories]="courrierCategories" (selected)="courrierTemplate = $event"/>
    </div>
  }

  <div [appFormError]="documentSelect.formInput">
    <label>{{ options.inputDocumentLabel }} *</label>

    <app-template-select #documentSelect="templateSelect" [categories]="documentCategories"
                         [options]="{name: 'modelDocument', placeholder: 'Sélectionnez un rapport', required: true, selectFirst: true}"
                         (selected)="selectDocumentTemplate($event)"/>
  </div>

  @if (advertising) {
    <p class="tw-w-full tw-pt-4 tw-font-semibold tw-text-center">
      @switch (advertising) {
        @case (codeCategories.ESTIMATION_EVALUATION) {
          <ng-container *ngTemplateOutlet="cityscan"/>
        }

        @case (codeCategories.ESTIMATION_EVALUATION_CITYSCAN) {
          <ng-container *ngTemplateOutlet="cityscan"/>
        }

        @default {
          Besoin d'une aide ou d'une amélioration ?<br/><br/><app-noteo-contact [module]="moduleCityscan"/>
        }
      }
    </p>

    <ng-template #cityscan>
      Découvrez comment améliorer votre rapport d'évaluation Camilla en intégrant les données provenant de
      <app-noteo-icon-text-cityscan/> <app-noteo-contact [module]="moduleCityscan"/>
    </ng-template>
  }

  <div class="tw-flex tw-flex-row-reverse tw-pt-4">
    <button type="submit" class="tw-btn-primary-info">{{ options.buttonCreateLabel }}</button>
  </div>
</form>
