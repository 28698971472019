import {Directive, inject, Input, OnDestroy, AfterViewInit, ElementRef} from '@angular/core';
import {filter, takeUntil} from 'rxjs/operators';
import {combineLatest, delay, ReplaySubject, Subject} from 'rxjs';
import {ActivatedRoute} from '@angular/router';

@Directive({selector: '[appRoutesAnchorScroll]'})
export class RoutesAnchorScrollDirective implements AfterViewInit, OnDestroy {
    private _activatedRoute = inject(ActivatedRoute);
    private _elementRef = inject(ElementRef<HTMLElement>);
    private _anchorSource = new ReplaySubject<string>(1);
    private readonly _onDestroy$ = new Subject<void>();

    @Input()
    set appRoutesAnchorScroll(value: string) {
        this._anchorSource.next(value);
    }

    ngAfterViewInit(): void {
        combineLatest([this._activatedRoute.fragment, this._anchorSource.asObservable()]).pipe(
            delay(500),
            filter(([fragment, anchor]) => fragment === anchor),
            takeUntil(this._onDestroy$),
        ).subscribe(() => this._elementRef.nativeElement.scrollIntoView({behavior: 'smooth'}));
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }
}
