import {NgModule} from '@angular/core';
import {AppCommunesSelectComponent} from '@features/communes/select/communes-select.component';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import {AppCommuneVoiesSelectComponent} from '@features/communes/item/voies/commune.voies-select.component';
import {AppCommuneQuartiersSelectComponent} from '@features/communes/item/quartiers/commune.quartiers-select.component';
import {
    AppCommuneConvivialNameComponent
} from '@features/communes/commune/convivial-name/commune.convivial-name.component';
import {AppCommuneSelectComponent} from '@features/communes/commune/select/commune-select.component';

@NgModule({
    declarations: [
        AppCommuneConvivialNameComponent,
        AppCommuneSelectComponent,
        AppCommunesSelectComponent,
        AppCommuneQuartiersSelectComponent,
        AppCommuneVoiesSelectComponent,
    ],
    exports: [
        AppCommuneConvivialNameComponent,
        AppCommuneSelectComponent,
        AppCommunesSelectComponent,
        AppCommuneQuartiersSelectComponent,
        AppCommuneVoiesSelectComponent,
    ],
    imports: [CommonModule, SharedModule],
})
export class CommunesModule {
}
