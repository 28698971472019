import {NgModule, Type} from '@angular/core';
import {
    NgEstimationEvaluationComponent, NgEstimationEvaluationDirective
} from '@shared/angularJS/up-ng/estimations/estimation-evaluation.component';
import {
    NgEstimationsReferencesComponent, NgEstimationsReferencesDirective
} from '@shared/angularJS/up-ng/estimations/estimations-references.component';
import {
    NgEstimationEvaluationReferencesCriteriaComponent, NgEstimationEvaluationReferencesCriteriaDirective
} from '@shared/angularJS/up-ng/estimations/estimations-item-evaluation-references-criteria.component';
import {
    NgEstimationLifetimeComponent, NgEstimationLifetimeDirective
} from '@shared/angularJS/up-ng/estimations/estimations-item-lifetime.component';
import {
    NgEstimationsAdvertisementComponent, NgEstimationsAdvertisementDirective
} from '@shared/angularJS/up-ng/estimations/estimations-advertisement.component';
import {
    NgEstimationsReferencesCriteriaComponent, NgEstimationsReferencesCriteriaDirective
} from '@shared/angularJS/up-ng/estimations/estimations-references-criteria.component';
import {
    NgEstimationEvaluationOnsaleReferencesCriteriaComponent, NgEstimationEvaluationOnsaleReferencesCriteriaDirective
} from '@shared/angularJS/up-ng/estimations/estimations-item-evaluation-onsale-references-criteria.component';

const components: Type<unknown>[] = [
    NgEstimationEvaluationComponent,
    NgEstimationEvaluationOnsaleReferencesCriteriaComponent,
    NgEstimationEvaluationReferencesCriteriaComponent,
    NgEstimationLifetimeComponent,
    NgEstimationsAdvertisementComponent,
    NgEstimationsReferencesComponent,
    NgEstimationsReferencesCriteriaComponent,
];
const directives: Type<unknown>[] = [
    NgEstimationEvaluationDirective,
    NgEstimationEvaluationOnsaleReferencesCriteriaDirective,
    NgEstimationEvaluationReferencesCriteriaDirective,
    NgEstimationLifetimeDirective,
    NgEstimationsAdvertisementDirective,
    NgEstimationsReferencesCriteriaDirective,
    NgEstimationsReferencesDirective,
];

@NgModule({declarations: [...components, ...directives], exports: components})
export class EstimationsComponentsModule {
}
