<div class="tw-mt-4">
  <button type="button" class="tw-btn-primary-septeo" (click)="askToBeContacted()">
    <img ngSrc="assets/images/septeo/logo.svg" height="16" width="16"> <span>Je souhaite être recontacté</span>
  </button>

  @if (options.withModuleInactif) {
    <div class="tw-mt-2 tw-flex tw-justify-center tw-items-center tw-gap-1 tw-text-gray-400 tw-text-xs">
      <div class="tw-h-2 tw-w-2 tw-rounded-full tw-bg-orange-400"></div> Module {{ labelModule }} inactif
    </div>
  }
</div>
