import {Component, inject, Input} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {CommuneFactory} from '@models/communes/commune/commune.factory';
import Commune from '@models/communes/commune/commune.model';

@Component({selector: 'app-commune-convivial-name', templateUrl: 'commune.convivial-name.component.html'})
export class AppCommuneConvivialNameComponent {
    private _communeFactory = inject(CommuneFactory);
    private _communeSource = new BehaviorSubject<Commune>(undefined!);
    private _commune$ = this._communeSource.asObservable();

    get commune$(): Observable<Commune> {
        return this._commune$;
    }

    @Input()
    set linkCommune(value: string) {
        this._communeFactory.getByLink$(value).pipe(take(1)).subscribe(commune => this._communeSource.next(commune));
    }
}
