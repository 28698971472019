import {Component, inject, OnInit} from '@angular/core';
import {UserService} from '@models/users/user/user.service';
import {Observable} from 'rxjs';
import User from '@models/users/user/user.model';
import {NgEstimation} from '@legacy/app/managers/ressources';
import {NgEstimationManager} from '@legacy/app/managers/managers';
import {NgInjectorService} from '@shared/angularJS/injector.ng.service';
import {ToasterService} from '@shared/toaster/toaster.service';
import {Router} from '@angular/router';
import Estimation from '@models/estimations/estimation/estimation.model';

@Component({selector: 'layout-estimation-evaluation', templateUrl: 'layout-estimation-evaluation.component.html'})
export class AppLayoutEstimationEvaluationComponent implements OnInit {
    private _ngInjectorService = inject(NgInjectorService);
    private _router = inject(Router);
    private _toasterService = inject(ToasterService);
    private _userService = inject(UserService);

    // Supprimer les injections des anciens manager
    private get ngEstimationManager(): NgEstimationManager {
        return this._ngInjectorService.getService<NgEstimationManager>('EstimationManager');
    }

    get estimation$(): Observable<NgEstimation> {
        return this.ngEstimationManager.current$;
    }

    get ESTIMATION_TYPE_EVALUATION(): string {
        return Estimation.types.EVALUATION;
    }

    get currentUser$(): Observable<User> {
        return this._userService.last$;
    }

    ngOnInit(): void {
        if (this.ngEstimationManager.current.type.code !== Estimation.types.EVALUATION) {
            this._toasterService.warning('Accès à l\'évaluation de votre bien',
                'Votre bien en estimation n\'est pas une évaluation, vous ne pouvez donc pas accéder à cette partie. Modifiez son type pour y avoir accès.');
            this._router.navigateByUrl('/app/estimations/' + this.ngEstimationManager.current.id.toString() + '/edit/general');
        }
    }
}
