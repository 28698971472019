import {NgModule} from '@angular/core';
import {AppCollectionForFilterPipe} from '@shared/collection/for-filter/collection.for-filter.pipe';
import {AppCollectionSortableDirective} from '@shared/collection/sortable/collection.sortable.directive';
import {AppCollectionItemErrorDirective} from '@shared/collection/item-error/collection.item-error.directive';
import {
    AppCollectionStackedImagesComponent
} from '@shared/collection/stacked-images/collection.stacked-images.component';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {LoadModule} from '@shared/load/load.module';
import {CollectionSelectionModule} from '@shared/collection/selection/collection-selection.module';
import {AppCollectionColumnSortDirective} from '@shared/collection/column-sort/collection-column.sort.directive';
import {CollectionColumnSortComponent} from '@shared/collection/column-sort/collection-column.sort.component';
import {FaModule} from '@shared/fontawesome/fa.module';
import {AppCollectionNavigationComponent} from '@shared/collection/navigation/collection-navigation.component';

@NgModule({
    declarations: [
        AppCollectionColumnSortDirective,
        AppCollectionForFilterPipe,
        AppCollectionItemErrorDirective,
        AppCollectionNavigationComponent,
        AppCollectionSortableDirective,
        AppCollectionStackedImagesComponent,
        CollectionColumnSortComponent,
    ],
    exports: [
        AppCollectionColumnSortDirective,
        AppCollectionForFilterPipe,
        AppCollectionItemErrorDirective,
        AppCollectionNavigationComponent,
        AppCollectionSortableDirective,
        AppCollectionStackedImagesComponent,
        CollectionSelectionModule,
    ],
    imports: [CollectionSelectionModule, CommonModule, FaModule, LoadModule, NgOptimizedImage],
})
export class CollectionModule {
}
