export default `
<div class="container-fluid container-fullw tw-bg-white advertisement">
  <div class="noty-container">
    <h1 class="row tw-text-center text-bold">Évaluation</h1>

    <h3 class="tw-text-center">Gagnez du temps dans la saisie et la mise en forme de vos rapports d’évaluation !</h3>

    <ul class="list-unstyled">
      <li>
        <h4>
          <span class="text-success"><fa-icon [icon]="['far', 'check-circle']"></fa-icon></span>
          Génération automatique de vos rapports
        </h4>
      </li>

      <li>
        <h4>
          <span class="text-success"><fa-icon [icon]="['far', 'check-circle']"></fa-icon></span>
          Créer votre bien à vendre en 1 clic
        </h4>
      </li>

      <li>
        <h4>
          <span class="text-success"><fa-icon [icon]="['far', 'check-circle']"></fa-icon></span>
          Alimenter votre base de données archivées
        </h4>
      </li>

      <li>
        <h4>
          <span class="text-success"><fa-icon [icon]="['far', 'check-circle']"></fa-icon></span>
          Calcul de la valeur du bien par méthode comparative
        </h4>
      </li>

      <li>
        <h4>
          <span class="text-success"><fa-icon [icon]="['far', 'check-circle']"></fa-icon></span>
          Envoi du rapport à votre client
        </h4>
      </li>
    </ul>

    <div class="slanted tw-text-center text-bold text-extra-large"><span>Respect des normes TRV / TEGOVA</span></div>

    <h3 class="tw-text-center">
      <esk-contact type="commerce" email-content="estimation.advertisement.request"></esk-contact>

      <span>pour obtenir plus d’informations sur la mise à disposition du module Évaluation.</span>
    </h3>
  </div>
</div>
`;
